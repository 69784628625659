<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <h1 class="font-weight-light mb-0">My Credentials</h1>
        <span class="overline">Don't share your credentials with anyone.</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat class="pa-5">
          <v-expansion-panels :value="0">
            <v-expansion-panel>
              <v-expansion-panel-header>
                My API Account Details
                <template v-slot:actions>
                  <v-icon color="primary">mdi-arrow-up-circle</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-switch
                  v-model="getFreelancerCompany.apiStatus"
                  @change="SwitchApiStatus"
                  inset
                  :label="
                    getFreelancerCompany.apiStatus
                      ? 'You Are In Live Mode (Real money is used)'
                      : 'You Are In Sandbox Mode (No Real money is used)'
                  "
                ></v-switch>

                <v-row>
                  <v-col lg="6" cols="sm" class="pb-2">
                    <v-card>
                      <v-row class="no-gutters">
                        <div class="col-auto">
                          <div class="cyan fill-height">&nbsp;</div>
                        </div>
                        <div class="col pa-3 py-4 cyan--text">
                          <h5 class="text-truncate text-uppercase">
                            Developer Community
                          </h5>
                          <p>
                            We have built a Community for all things integration
                            (Forum, stop by step integration) related. Please
                            visit the
                            <a href="/#/community">developer Community</a>
                          </p>
                        </div>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col lg="6" cols="sm" class="pb-2">
                    <v-card>
                      <v-row class="no-gutters">
                        <div class="col-auto">
                          <div class="cyan fill-height">&nbsp;</div>
                        </div>
                        <div class="col pa-3 py-4 cyan--text">
                          <h5 class="text-truncate text-uppercase">
                            API reference
                          </h5>
                          <p>
                            For the API reference you can view the latest
                            documentation with snippets of code.Please visit the
                            <a href="/#/apiDocumentation">API reference</a>
                          </p>
                        </div>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Display API Credentials
                <template v-slot:actions>
                  <v-icon color="primary">mdi-arrow-up-circle</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-form @submit.prevent="onBasicSubmit">
                    <v-text-field
                      v-model="fetchFreelancerCompany.publicKey"
                      label="Public Key"
                      outlined
                      disabled
                      dense
                    />

                    <v-text-field
                      v-model="fetchFreelancerCompany.privateKey"
                      :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showKey ? 'text' : 'password'"
                      label="Private Key"
                      @click:append="showKey = !showKey"
                      outlined
                      dense
                    ></v-text-field>

                    <v-btn
                      type="submit"
                      @click="resetKeys"
                      variant="primary"
                      class="mt-4"
                      >Reset Keys</v-btn
                    >
                  </v-form>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                API Settings
                <template v-slot:actions>
                  <v-icon color="primary">mdi-arrow-up-circle</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-form @submit.prevent="onBasicSubmit">
                    <v-text-field
                      v-model="fetchFreelancerCompany.callbackURL"
                      label="Callback URL"
                      outlined
                      dense
                    />

                    <v-btn
                      type="submit"
                      @click="saveCallbackURL"
                      variant="primary"
                      class="mt-4"
                      >Save</v-btn
                    >
                  </v-form>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase";
import Vue from "vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      showKey: false,
    };
  },

  created() {
    this.$store.dispatch("fetchMyCompany");
  },

  computed: {
    ...mapState(["freelancerCompany"]),
    ...mapGetters(["getFreelancerCompany", "messageAlert"]),

    fetchFreelancerCompany() {
      if (this.getFreelancerCompany !== undefined) {
        return this.getFreelancerCompany;
      } else {
        return {};
      }
    },
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyCompany");
      }
    },
  },

  methods: {
    resetKeys() {
      this.$store.dispatch("resetMyCompanyApiKeys");
    },

    SwitchApiStatus(e) {
      console.log("THEME:::", e);
      //update api profile
      this.$store.dispatch(
        "updateCompanyApiStatus",
        this.getFreelancerCompany.apiStatus
      );
    },

    saveCallbackURL(){
      this.$store.dispatch(
        "saveCallbackURL",
        this.getFreelancerCompany.callbackURL
      );},
  },
};
</script>
